<template>
  <ThemeProvider :color="{ middle: { 'bg-base': '#fff' }, primary: { DEFAULT: '#3D4687' } }">
    <div class="login-box rounded-sm" v-if="!userId || userId == 'undefined'">
      <div class="box-title">账号登录</div>
      <a-form :model="form" :wrapperCol="{ span: 24 }" @finish="login"
        class="login-form p-lg  xl:p-xl h-fit text-text">
        <a-form-item :rules="[{ required: true, message: '请输入用户名' }]" name="username">
          <a-input v-model:value="form.username" placeholder="请输入用户名" class="login-input h-[40px]">
            <template #prefix>
              <user-outlined />
            </template>
          </a-input>
        </a-form-item>
        <a-form-item :rules="[{ required: true, message: '请输入密码' }]" name="password">
          <a-input v-model:value="form.password" placeholder="请输入登录密码" autocomplete="off" class="login-input h-[40px]" type="password">
            <template #prefix>
              <lock-outlined />
            </template>
          </a-input>
        </a-form-item>


        <a-row :gutter="16">
          <a-col class="gutter-row" :span="16">
            <a-form-item :rules="[{ required: true, message: '请输入验证码' }]" name="verCode">
              <a-input v-model:value="form.verCode" size="large" type="text" placeholder="请输入验证码">
                <template #prefix>
                  <safety-outlined />
                </template>
              </a-input>
            </a-form-item>
          </a-col>
          <a-col class="gutter-row" :span="8">
            <div @click="getCaptcha"> <img :src="captcha" class="captcha-img"></div>
          </a-col>
        </a-row>


        <a-form-item>
          <a-checkbox>记住密码</a-checkbox>
        </a-form-item>
        <a-button htmlType="submit" class="h-[40px] w-full sub-btn" type="primary" :loading="loading"> 登录 </a-button>
        <!-- <div class="terms">
        登录即代表您同意我们的
        <span class="font-bold">用户条款 </span>、<span class="font-bold"> 数据使用协议 </span>、以及
        <span class="font-bold">Cookie使用协议</span>。
      </div> -->

      </a-form>
    </div>

  </ThemeProvider>
</template>
<script lang="ts" setup>
import { ref } from 'vue';
import { useAccountStore } from '@/store';
import { ThemeProvider } from 'stepin';
import { useRouter } from 'vue-router';
import { reactive } from 'vue';
import { onMounted } from 'vue';



export interface LoginFormProps {
  username: string;
  password: string;
  verCode: string;
}
const loading = ref(false);
const userId = ref('');
// 验证码
const captcha = ref('');
const form = reactive({
  username: undefined,
  password: undefined,
  verCode: undefined
});

// 获取参数
const route = useRouter();
const accountStore = useAccountStore();
userId.value = String(route.currentRoute.value.query.userId);
if (userId) {
  hrefLogin();
}
const emit = defineEmits<{
  (e: 'success'): void;
  (e: 'failure', reason: string): void;
}>();
// 登录
function login(form: LoginFormProps) {
  loading.value = true;
  accountStore
    .login(form.username, form.password, form.verCode)
    .then((res) => {
      emit('success');
    })
    .catch((e) => {
      // 如果是验证码不正确刷新验证码
      if(e.msg== '验证码不正确') {
        getCaptcha();
      }
      emit('failure', e.msg);
    })
    .finally(() => (loading.value = false));
}
// 跳转登录
function hrefLogin() {
  accountStore
    .hrefLogin()
    .then((res) => {
      emit('success');
    })
    .catch((e) => {
      emit('failure', e.message);
    })
    .finally(() => (loading.value = false));
}
// 获取验证码
function getCaptcha() {
  // 清除已输入的验证码
  form.verCode = undefined;
  accountStore
    .getCaptcha()
    .then((res) => {
      const blob = new Blob([res]);
      let src = window.URL.createObjectURL(blob);
      captcha.value = src;
    })
    .catch((e) => {
      // emit('failure', e.message);
    })
    .finally(() => (loading.value = false));
}
onMounted(() => {
  getCaptcha();
});
</script>
<style lang="less" scoped>
.login-box {
  width: 550px;
  padding: 74px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 8px;
  margin: 0 auto;
  margin-top: 113px;
}

.box-title {
  color: #3d4687;
  font-size: 24px;
  line-height: 24px;
  text-align: center;
}

.ant-form-item {
  margin-bottom: 26px;
}

:deep(.ant-checkbox) {
  top: 0.3em
}

.captcha-img {
  width: 100%;
  height: 40px;
}

:deep(.ant-form-item-explain-error) {
  color: #ff4d4f;
}
</style>
